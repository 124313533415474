$big-arrow: 50px;
$medium-arrow: 30px;
$small-arrow: 20px;

.carousel {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    position: relative;

    .prev, .next {
        position: absolute;
        z-index: 999;
        width: 4vw;

        img {
            width: 60%;
        }
    }

    .next {
        text-align: right;
        right: 0;
    }

    @keyframes show {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }

    .show {
        animation: show 500ms ease-in;
    }

    .hidden {
        display: none;
    }
}
