.contact {
    min-height: 80vh;
    height: max-content;
    margin: auto;
    padding: 5vw 10vw;

    h2 {
        text-align: center;
        font-weight: bold;
        font-size: 3vw;

        @media screen and (max-width: 768px) and (orientation: portrait)  {
            font-size: 5vw;
        }
    }

    .form {
        margin: auto;
        margin-top: 5vw;
        width: 50vw;
        background: #f6f8fc;
        padding: 5vw;
        color: gray;

        @media screen and (max-width: 768px) and (orientation: portrait)  {
            width: 100%;
        }

        .label {
            font-size: 2vw;
            margin-bottom: .5vw;

            @media screen and (max-width: 768px) and (orientation: portrait)  {
                font-size: 3vw;
            }
        }

        textarea {
            height: 12vw;

            @media screen and (max-width: 768px) and (orientation: portrait)  {
                height: 20vw;
            }
        }

        input,
        textarea {
            border: solid 2px #eee;
            width: 100%;
            box-sizing: border-box;
            margin-bottom: 1vw;
            font-size: 1.5vw;
            padding: .5vw 1vw;


            @media screen and (max-width: 768px) and (orientation: portrait)  {
                font-size: 3vw;
                padding: 1vw;
                margin-bottom: 2vw;
            }
        }
    }

    button {
        border: 0;
        background: none;
        padding: 0;
        outline: none;
    }

    .buttons {
        display: flex;
        margin-top: 10px;

        .button {
            cursor: pointer;
            width: 12vw;
            margin-right: 1vw;

            @media screen and (max-width: 768px) and (orientation: portrait)  {
                width: 20vw;
            }

            img {
                width: 100%;
            }
        }
    }
}
