.blog {

    .header {
        width: 100vw;
        margin-bottom: 4vw;

        img {
            width: 100vw;
            object-fit: cover;
            height: calc(597 / 1922 * 100vw);

            @media screen and (max-width: 768px) and (orientation: portrait)  {
                height: 20vh;
            }
        }
        
    }

    .list {
        padding: 0 10vw;

        h2 {
            margin: 1vw 0 2vw;
            font-weight: bold;
            font-size: 2.5vw;
            text-align: center;

            @media screen and (max-width: 768px) and (orientation: portrait)  {
                font-size: 5vw;
                margin: 1vw 0 4vw;
            }
        }

        a {
            text-decoration: none;
            color: black;

            .posts {
                padding: 0 2vw;
                // margin: 2vw 0;
                .post {
                    padding: 2.5vw 0;
                    border-bottom: solid 2px lightgrey;
                    display: flex;
                    // align-items: center;
                    
                    .thumbnail {
                        width: 30%;
                        margin-right: 3vw;

                        @media screen and (max-width: 768px) and (orientation: portrait)  {
                            width: 50%;
                        }
        
                        img {
                            width: 100%;
                        }
                    }
        
                    .caption {
                        width: 70%;

                        h3 {
                            font-size: 1.6vw;
                            font-weight: bold;
                            line-height: 1.3;
                            margin-bottom: 1vw;

                            @media screen and (max-width: 768px) and (orientation: portrait)  {
                                font-size: 2.2vw;
                            }
                        }
            
                        p {
                            color: grey;
                            font-size: 1.2vw;
                            margin-top: 0.1vw;

                            @media screen and (max-width: 768px) and (orientation: portrait)  {
                                font-size: 2vw;
                            }
                        }
            
                        img {
                            width: 30vw;
                        }
                    }
        
                }
            }

            &:last-child {
                .posts .post {
                    border: none;
                }
            }
        }
    }


    .view_more {
        background: #f6f8fc;
        text-align: center;
        padding: 0.5vw 0;
        margin-bottom: 1vw;
        color: grey;
        font-weight: bold;
    }
}

.blog_detail {
    padding: 0 15vw 2vw;
    min-height: 100vh;
    line-height: 1.2;

    h1 {
        font-size: 2.5vw;
        font-weight: bold;
        margin: 1vw 0;

        @media screen and (max-width: 768px) and (orientation: portrait)  {
            font-size: 4vw;
        }
    }

    .back {
        border-bottom: solid 2px lightgray;
        padding: 2vw 0;
        font-size: 2vw;
        margin-bottom: 5vw;

        @media screen and (max-width: 768px) and (orientation: portrait)  {
            font-size: 4vw;
        }

        a {
            text-decoration: none;
            color: #99b6d6;
        }
    }

    .date {
        color: gray;
        font-size: 1.5vw;

        @media screen and (max-width: 768px) and (orientation: portrait)  {
            font-size: 3vw;
        }
    }

    .content {
        margin-top: 5vw;

        * {
            color: #ababab !important;

            @media screen and (max-width: 768px) and (orientation: portrait)  {
                font-size: 3vw;
            }
        }

        img {
            display: block;
            margin: 0 auto;
            max-width: 100%;
        }
    }
}
