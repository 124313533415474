.nav {
    display: flex;
    justify-content: space-between;
    margin: 0 10vw;
    padding: 2vw 0;
    align-items: center;
    border-bottom: solid 1px lightgrey;
    .logo {
        width: 20vw;

        @media screen and (max-width: 768px) and (orientation: portrait)  {
            width: 35vw;
        }

        img {
            max-width: 100%;
        }
    }

    .links {
        a {
            margin: 1vw;
            font-size: 1.4vw;
            font-weight: bold;
            color: black;
            text-decoration: none;

            @media screen and (max-width: 768px) and (orientation: portrait)  {
                font-size: 3vw;
                margin: 1vw 2vw;
            }

            &.active {
                color: #1f80fb;
            }
        }
    }
}
