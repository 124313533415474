.home {
	color: #ababab;

	h2 {
		color: black;
		font-weight: bold;
		font-size: 2.5vw;
		margin-bottom: 3vw;
		text-align: center;

		@media screen and (max-width: 768px) and (orientation: portrait) {
			font-size: 5vw;
		}
	}

	.banner {
		display: flex;
		// align-items: center;
		min-height: calc(1121 / 1920 * 100vw);
		width: 100vw;
		background: url(https://cdngarenanow-a.akamaihd.net/webid/schoolarship/BG.jpg);
		background-size: cover;
		color: white;
		padding: 10vw 0;

		@media screen and (max-width: 768px) and (orientation: portrait) {
			flex-direction: column;
			align-items: center;
			padding-bottom: 15vw;
		}

		.content {
			padding: 0 10vw;
			max-width: 50vw;

			@media screen and (max-width: 768px) and (orientation: portrait) {
				order: 2;
				max-width: 90vw;
				text-align: center;
			}

			* {
				margin-bottom: 2vw;
				line-height: 1.6;

				@media screen and (max-width: 768px) and (orientation: portrait) {
					line-height: 1.2;
					font-size: 4vw;
				}
			}

			.logo {
				@media screen and (max-width: 768px) and (orientation: portrait) {
					text-align: center;
				}

				img {
					width: 132%;
					@media screen and (max-width: 768px) and (orientation: portrait) {
						width: 50vw;
					}
				}
			}
		}

		.illust {
			@media screen and (max-width: 768px) and (orientation: portrait) {
				order: 1;
				margin-bottom: 5vw;
			}
			img {
				max-width: 40vw;

				@media screen and (max-width: 768px) and (orientation: portrait) {
					max-width: 60vw;
				}
			}
		}

		.apply {
			@media screen and (max-width: 768px) and (orientation: portrait) {
				text-align: center;
			}

			a {
				@media screen and (max-width: 768px) and (orientation: portrait) {
					display: inline-block;
					margin: 0;
				}
			}

			.apply_button {
				background: url(https://cdngarenanow-a.akamaihd.net/webid/schoolarship/Button_Apply.png);
				transition: all 0.2s ease-in-out;
				height: 5vw;
				width: 25vw;
				background-size: contain;
				background-repeat: no-repeat;

				&:hover {
					background: url(https://cdngarenanow-a.akamaihd.net/webid/schoolarship/Button_Apply-Big.png);
					background-size: contain;
					background-repeat: no-repeat;
				}

				@media screen and (max-width: 768px) and (orientation: portrait) {
					height: 10vw;
					width: 40vw;
					margin: 4vw 0;
				}
			}
		}

		.closed {
			@media screen and (max-width: 768px) and (orientation: portrait) {
				margin: 5vw 0 8vw;
			}
		}
	}

	.about {
		text-align: center;
		padding: 2vw 15vw 7vw;

		@media screen and (max-width: 768px) and (orientation: portrait) {
			margin-top: -5vw;
		}

		p {
			text-align: justify;

			@media screen and (max-width: 768px) and (orientation: portrait) {
				font-size: 3vw;
			}
		}

		h3 {
			text-align: left;
			font-size: 1.2vw;
			margin: 2vw 0;
			font-weight: bold;
			color: gray;

			@media screen and (max-width: 768px) and (orientation: portrait) {
				margin: 5vw 0;
				text-align: center;
				font-size: 3vw;
			}
		}

		.business_logos {
			display: flex;
			justify-content: space-around;
			align-items: center;

			@media screen and (max-width: 768px) and (orientation: portrait) {
				flex-direction: column;
			}

			.logo {
				width: 20vw;
				&:nth-child(2) {
					img {
						width: 15vmax;
						margin: auto;
					}
				}

				@media screen and (max-width: 768px) and (orientation: portrait) {
					width: 35vw;

					div:nth-child(2) {
						font-size: 3vw;
						margin: 2vw 0 4vw;
					}
				}

				.image_cont {
					position: relative;
					height: 7vw;
					margin-bottom: 0.5vw;

					@media screen and (max-width: 768px) and (orientation: portrait) {
						height: initial;
					}
				}

				img {
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
					display: block;
					max-width: 100%;
					max-height: 100%;

					@media screen and (max-width: 768px) and (orientation: portrait) {
						position: relative;
						top: initial;
						left: initial;
						transform: initial;
					}
				}
			}
		}
	}

	.program {
		padding: 5vw 15vw;
		text-align: justify;
		background: #f6f8fc;

		@media screen and (max-width: 768px) and (orientation: portrait) {
			p {
				font-size: 3vw;
			}
		}

		ul {
			list-style: '-';
		}

		.separator {
			width: 70vw;
			margin: 5vw 0 3vw;

			@media screen and (max-width: 768px) and (orientation: portrait) {
				overflow: hidden;
			}

			img {
				max-width: 100%;

				@media screen and (max-width: 768px) and (orientation: portrait) {
					max-width: 140%;
				}
			}
		}

		.list {
			margin-bottom: 4vw;
		}

		.timeline {
			display: flex;
			justify-content: space-between;
			text-align: center;
			margin-top: 5vw;

			@media screen and (max-width: 768px) and (orientation: portrait) {
				flex-wrap: wrap;
				justify-content: center;

				> div {
					&:nth-child(odd) {
						width: calc(100% / 4);
						margin-bottom: 2vw;
					}

					&:nth-child(even) {
						width: calc(100% / 18);
						margin-top: -5vw;
					}
				}
			}

			.item {
				img {
					max-height: 7vw;
					height: 150px;

					@media screen and (max-width: 768px) and (orientation: portrait) {
						max-height: 15vw;
						height: 15vw;
					}
				}
			}

			.dash {
				position: relative;
				transform: translateY(-85%);
				align-self: center;
				img {
					max-width: 3vw;
				}
			}

			.content {
				margin-top: 1.3vw;

				div {
					position: relative;
					font-size: 1.1vw;
					width: 100%;
					height: 1.5vw;

					@media screen and (max-width: 768px) and (orientation: portrait) {
						font-size: 2vw;
						height: 3vw;
					}

					strong,
					span {
						white-space: nowrap;
						position: absolute;
						left: 50%;
						transform: translateX(-50%);
					}
				}

				strong {
					color: black;
					// max-width: 50px;
				}
			}
		}
	}

	.cta {
		padding: 5vw;

		@media screen and (max-width: 768px) and (orientation: portrait) {
			p {
				margin: 0 auto;
				width: 70vw;
				font-size: 2.5vw;
			}
		}

		* {
			text-align: center;
		}

		h2 {
			margin-bottom: 1vw;
		}

		a {
			display: block;
			margin: auto;
			margin-top: 2.5vw;

			.apply_button {
				width: 20vw;
				margin: auto;
			}
		}

		.apply_button {
			background: url(https://cdngarenanow-a.akamaihd.net/webid/schoolarship/Button_Apply.png);
			transition: all 0.2s ease-in-out;
			height: 5vw;
			width: 25vw;
			background-size: contain;
			background-repeat: no-repeat;

			&:hover {
				background: url(https://cdngarenanow-a.akamaihd.net/webid/schoolarship/Button_Apply-Big.png);
				background-size: contain;
				background-repeat: no-repeat;
			}

			@media screen and (max-width: 768px) and (orientation: portrait) {
				height: 6vw;
				width: 33vw;
				margin: 0;
			}
		}
	}

	.testimonies {
		padding: 5vw 15vw;
		background: #f6f8fc;

		.testimony_image {
			margin-right: 3vw;
			width: 72vw;

			@media screen and (max-width: 768px) and (orientation: portrait) {
				width: 30vw;
				margin-top: 2vw;
			}

			img {
				max-width: 100%;
			}
		}

		.testimony_item {
			padding: 0 4vw;
			display: flex;
			align-items: center;

			@media screen and (max-width: 768px) and (orientation: portrait) {
				flex-direction: column;
			}

			b,
			strong {
				display: block;
				// padding-top: 30px;
			}
		}

		.testimony_content {
			@media screen and (max-width: 768px) and (orientation: portrait) {
				margin-top: 3vw;
				padding: 0 5vw;
			}

			* {
				font-size: 1.25vw;

				@media screen and (max-width: 768px) and (orientation: portrait) {
					font-size: 3vw;
				}
			}

			p:first-child {
				margin-bottom: 1.5vw;
			}
		}
	}

	.faq {
		padding: 5vw 15vw 3vw;

		@media screen and (max-width: 768px) and (orientation: portrait) {
			p {
				font-size: 3vw;
			}

			h2 {
				margin-bottom: 8vw;
			}
		}

		strong {
			color: black;
		}

		a {
			color: #ababab;
		}
	}
}

.navigation {
	margin-right: 1vw;
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	position: fixed;
	right: 0;
	top: 26vh;
	transform: translateY(-50%);

	a {
		display: flex;
		align-items: center;
		text-decoration: none;
		color: #1f80fb;

		div {
			font-size: 1.2vw;
		}
	}
	.bar {
		position: relative;
		margin: -0.5vw 1.3vw -0.5vw 0;
		height: 3vw;
		img {
			max-height: 100%;
			width: 3px;
		}
	}

	.icon {
		position: relative;
		z-index: 2;
		width: 2vw;
		margin-right: 0.4vw;
		margin-left: 1vw;
		img {
			max-width: 100%;
		}
	}

	@media (max-width: 768px) {
		display: none;
	}
}
