@import "reset";

@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro&display=swap");

div,
p {
    font-size: 1.4vw;
}

b,
strong {
    font-weight: bold !important;
}

* {
    font-family: "Source Sans Pro" !important;
}

p {
    line-height: 1.6;
}

body {
    max-width: 100vw;
    overflow-x: hidden;
}

.react-responsive-modal-modal {
    height: 20vw;
    width: 40%;
}

.modal-ads {
    height: initial;
    width: initial;
    max-width: 40% !important;

    @media screen and (max-width: 768px) and (orientation: portrait)  {
        max-width: 80% !important;
    }

    .ads-modal-content {
        img {
            width: 100%;
        }
    }
}

.react-responsive-modal-modal.modal-mt {
    .ads-modal-content {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        
    }
}


.alert-modal-content {
    height: 100%;
    margin: 0 2vw;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    * {
        text-align: center;
        line-height: 1.3;
    }

    h2 {
        font-size: 1.6vw;
        font-weight: bold;
        margin-bottom: 1vw;
    }

    p {
        font-size: 1.3vw;
    }
}