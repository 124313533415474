.footer {
    padding: 0 10vw;
    color: lightgrey;

    @media screen and (max-width: 768px) and (orientation: portrait)  {
        padding: 5vw 10vw;
    }

    .logo {
        margin-bottom: 1vw;

        img {
            width: 10vw;

            @media screen and (max-width: 768px) and (orientation: portrait)  {
                width: 20vw;
            }
        }
    }

    .address {
        padding: 2vw;
        border-top: solid 1px lightgrey;
        border-bottom: solid 1px lightgrey;


    }

    .ico {
        margin-top: .5vmax;
        width: 1.5vmax;
        height: 1.5vmax;
        img {
            width: 100%;
            height: 100%;
        }
    }

    a {
        color: lightgray;
    }

    .more_info {
        margin: 2vw auto;
        width: 100%;
        text-align: center;
    }

    div,
    p {
        font-size: 1.2vw !important;

        @media screen and (max-width: 768px) and (orientation: portrait)  {
            font-size: 3vw !important;
        }
    }

    .detail {
        width: 50vw;

        @media screen and (max-width: 768px) and (orientation: portrait)  {
            width: initial;
        }
    }
}
